<template>
  <v-dialog v-model="opened" data-app @input="onToggle()" max-width="650">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>{{ user.n_id ? 'Редактирование менеджера' : 'Создание менеджера' }}</v-card-title>
      <v-card-text v-if="!loading">
        <v-form lazy-validation
                ref="form">
          <v-row class="row-d-block">
            <v-subheader>Логин</v-subheader>
            <v-text-field
                outlined
                v-model="user.login"></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Имя</v-subheader>
            <v-text-field
                outlined
                v-model="user.fname"></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Код от телеграм бота (для уведомлений)</v-subheader>
            <v-text-field
                outlined
                v-model="user.telegram_id"></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-checkbox
                v-model="user.admin"
                true-value="1"
                false-value="0"
                dense
                label="Админ (полный доступ к городу, разделам Менеджеры, Финансы и тд)"/>
          </v-row>
          <v-row class="row-d-block">
            <v-checkbox
                v-model="user.hr"
                true-value="1"
                false-value="0"
                dense
                label="HR (Будут доступны только разделы с клинерами)"/>
          </v-row>
          <v-row class="row-d-block">
            <v-checkbox
                v-model="user.deleted"
                true-value="1"
                false-value="0"
                dense
                label="Заблокирован (блокировка доступа к црм)"/>
          </v-row>
          <v-row class="row-d-block">
            <v-checkbox
                v-model="user.okk"
                true-value="1"
                false-value="0"
                dense
                label="ОКК (Отдел контроля качества)"/>
          </v-row>
          <v-row class="row-d-block">
            <v-checkbox
                v-model="user.leads_distribution_enabled"
                true-value="1"
                false-value="0"
                dense
                label="Участвует в распределении лидов"/>
          </v-row>
          <v-row v-if="+user.leads_distribution_enabled" class="row-d-block">
            <v-subheader>Группа распределения</v-subheader>
            <v-select
                v-model="user.leads_distribution_type"
                :items="[{name: 'Продажи', value: ''}, {name: 'Служба поддержки', value: 'support'}]"
                item-text="name"
                item-value="value"
                dense
                outlined
            />
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Номера телефонии/sip манго (через запятую, например: "sip:sasha@vpbx40asdfas, +79998887766")
            </v-subheader>
            <v-text-field
                outlined
                v-model="user.telephony_numbers"
            ></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Пароль</v-subheader>
            <v-text-field
                autocomplete="off"
                outlined
                v-model="user.password"
                @change="passwordChanged = true"
                :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_pass ? 'text' : 'password'"
                @click:append="show_pass = !show_pass"
                required
                :rules="passRules"></v-text-field>
          </v-row>
          <v-row class="row-d-block">
            <v-subheader>Повторите пароль</v-subheader>
            <v-text-field
                autocomplete="off"
                outlined
                v-model="user.re_password"
                :append-icon="show_re_pass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_re_pass ? 'text' : 'password'"
                @click:append="show_re_pass = !show_re_pass"
                required
            ></v-text-field>
          </v-row>

        </v-form>
      </v-card-text>
      <v-card-text v-else>
        <v-skeleton-loader type="card"/>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-btn @click="updateUser()" :loading="loading_save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      opened: false,
      user: {},
      passRules: [],
      show_pass: false,
      show_re_pass: false,
      loading_save: false,
    }
  },
  computed: {
    loading() {
      return this.user.n_id && !this.user.login;
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.getUser()
    },
    onToggle() {
      this.user = {}
      this.passRules = []
    },
    getUser() {
      if (this.user.n_id) {
        this.$store.state.server.request(`users/get/${this.user.n_id}`, {}, (data) => {
          this.user = data.response;
          if (+this.user.leads_distribution_enabled) this.user.leads_distribution_enabled = '1';
        }, (data) => {
          if (data && data.error) this.$root.notify(data.error, 'error')
        })
      }
    },
    updateUser() {
      if (!this.user.n_id) this.passRules = [
        v => !!v || 'Введите пароль'
      ]
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.loading_save = true;
          if (this.user.n_id) {
            this.$store.state.server.request(`users/update/${this.user.n_id}`, this.user, (data) => {
              this.user = data.response
              this.$root.notify('Менеджер сохранен', 'success')
              this.opened = false;
              this.loading_save = false
            }, (data) => {
              if (data && data.error) this.$root.notify(data.error, 'error')
              this.loading_save = false

            })
          }
          if (!this.user.n_id) {
            this.$store.state.server.request('users/create/', this.user, (data) => {
              this.user = data.response;
              this.$root.notify('Менеджер создан', 'success')
              this.opened = false;
              this.loading_save = false

            }, (data) => {
              if (data && data.error) this.$root.notify(data.error, 'error')
              this.loading_save = false
            })
          }
        }
      }, 100)
    },
  },

}
</script>
